import React from 'react';

import './PrivacyPolicy.scss'

const PrivacyPolicyPage = () => {
    return <div className="privacy-policy">
        <h1>Our Privacy Policy</h1>
        <h2>Last Updated: 2024-09-12</h2>
        <p>
            Your privacy is important to us. This Privacy Policy outlines how we handle your personal data when you visit our website.
        </p>

        <div>
            <h2>Data Collection</h2>
            <p>We do not collect any personal data from you directly through our website. We do not store, request, or process any information such as names, emails, or demographic details.</p>
        </div>
        <div>
            <h2>Cookies</h2>
            <p>Our website does not use cookies to store information on your device. We do not track or monitor your browsing activity through cookies.</p>
        </div>
        <div>
            <h2>Google Analytics</h2>
            <p>We use Google Analytics to understand how visitors interact with our website. Google Analytics may collect certain information, such as:</p>
            <ul>
                <li>IP address</li>
                <li>Browser type</li>
                <li>Pages visited</li>
                <li>Time spent on the site</li>
            </ul>
            <p>This data helps us analyze website traffic and improve user experience. For more information on how Google handles your data, please review Google’s Privacy Policy.</p>
        </div>
        <div>
            <h2>Your Data Rights</h2>
            <p>Since we do not collect or store personal data from our website, there are no personal data requests (such as access, correction, or deletion) that apply. If you have any concerns regarding Google Analytics, please refer to Google’s privacy policy and terms.</p>
        </div>
        <div>
            <h2>Data Security</h2>
            <p>While we do not collect personal data, we take precautions to ensure that any third-party tools, such as Google Analytics, handle data securely.</p>
        </div>
        <div>
            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy as needed. Any changes will be posted on this page, with the last updated date indicated at the top.</p>
        </div>
        <div>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us at: info@cawilderness.com</p>
        </div>
    </div>;
}

export default PrivacyPolicyPage;